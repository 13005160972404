@import url('https://fonts.googleapis.com/css2?family=Inter:ital,opsz,wght@0,14..32,100..900;1,14..32,100..900&display=swap');

@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

html {
  @apply text-baseContent bg-base-200;
}

@layer base {
  h2 {
    @apply text-3xl lg:text-4xl font-semibold tracking-tight mb-4;
  }

  h3 {
    @apply text-lg lg:text-xl font-semibold tracking-tight;
  }

  p {
    @apply lg:text-lg text-baseContentAlt break-words leading-relaxed lg:leading-relaxed;
  }

  label {
    @apply block text-sm lg:text-base font-medium text-baseContentAlt mb-2;
  }

  section {
    @apply w-full py-12 md:py-24;
  }
}

@layer utilities {
  .text-balance {
    text-wrap: normal;
  }
}

@layer components {
  .primary-button {
    @apply block bg-primary text-primaryContent rounded-md text-center px-4 py-2 mt-2 hover:bg-primaryAlt;
  }

  .primary-button-inverse {
    @apply block bg-base-100 text-primary font-semibold border border-primary rounded-md text-center px-4 py-2 mt-2 hover:bg-primary hover:text-base-100;
  }

  .secondary-button {
    @apply block bg-secondary text-secondaryContent rounded-md text-center px-4 py-2 mt-2 hover:bg-secondaryAlt;
  }

  .secondary-button-inverse {
    @apply block bg-base-100 text-secondary font-semibold border border-secondary rounded-md text-center px-4 py-2 mt-2 hover:bg-secondary hover:text-base-100;
  }
}
